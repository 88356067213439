@import "./variables.scss";

.csd-yellow {
  color: $csd-yellow;
}

.csd-gray {
  color: $csd-gray;
}

.csd-blue {
  color: $csd-blue;
}

.csd-orange {
  color: $csd-orange;
}

.csd-ping {
  color: $csd-pink;
}

.csd-purple {
  color: $csd-purple;
}

.csd-white {
  color: $csd-white !important;
}

/* search bar */
.searchbar {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-items: center;

    .searchbar-input {
        width: 50%;
    }

    .searchbar-btn {
        margin-left: 16px;
        width: 111px !important;
        height: 36px;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.error-text {
  font-size: 14px;
  color: $csd-orange;
  text-align: center;
}
