@import "../../variables.scss";

.notAuth {

  .centered {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .csdFlow {
    background-image: url("../../assets/images/csd-flow-blue.svg");
    background-repeat: no-repeat;
    background-size: 100% 200%;
    background-position: calc(100vw - 400px) calc(100vh - 700px);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .alert {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
    display: flex;
    padding: 14px 14px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
  }

  .alert-icon {
    padding: 0px 16px 0px 0px;
  }

  button {
    color: $csd-white;
    margin-left: auto;
    background-color: $csd-purple;
    background-image: none;

    &:hover,
    &:active {
      background-color: $csd-purple;
    }
  }
}
