.users_table-result { 
    text-align: end;
}

.users_table-trash { 
    background-color: transparent;
}

.users_table-active {
    display: flex;
}

.users_table-cercle-ok {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: green;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;
}

.users_table-cercle-ko {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;
}

.users_add-user {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  
    div {
      font-size: 12px;
      height: 32px;
      padding: 0;
      text-align: left;
    }
}

.users_account-status {
  display: flex; 
}

.users_account-status-icon {
  padding-right: 8px;
  margin-top: 4px;
}

.users_account-status-registered {
  color: #000000;
}

.users_account-status-invited {
  color: #838485;
}
