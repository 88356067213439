@import "../../variables.scss";

.addEditUser {
  font-size: 14px;
  text-align: left;

  .user-profile,
  .user-role {
    display: flex;
    margin-bottom: 24px;
  }
}

.user-Legend {
  text-align: right;
  span {
    color: $csd-orange;
  }
}

.account-status {
  padding: 16px;
}

.addEditUser-bold {
  font-weight: bold;
}

.user-Error_Text {
  font-size: 12px;
  color: $csd-orange;
  margin-top: 0;
}

.user-location {
  margin-bottom: 24px;
}
