.locations_table-result {
    text-align: end;
}

.locations_table-trash {
    background-color: transparent;
}

.locations_table-active {
    display: flex;
}

.locations_table-cercle-ok {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: green;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;
}

.locations_table-cercle-ko {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;
}

.locations_add-location {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    div {
      font-size: 12px;
      height: 32px;
      padding: 0;
      text-align: left;
    }
}
