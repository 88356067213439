@import "../../variables.scss";

.login {

  .centered {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .csdFlow {
    background-image: url("../../assets/images/csd-flow-blue.svg");
    background-repeat: no-repeat;
    background-size: 100% 200%;
    background-position: calc(100vw - 400px) calc(100vh - 700px);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  button {
    color: $csd-white;
    margin-left: auto;
    background-color: $csd-purple;
    background-image: none;

    &:hover,
    &:active {
      background-color: $csd-purple;
    }
  }
}
